import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AccordionItem from './AccordionItem';
import Button from '../../../globals/buttons/Button';
import './accordion.scss';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

const Accordion = (props) => {
  const { fields, btnText, backgroundColor } = props;
  const [activeIndex, setActiveIndex] = useState(-1);

  return (
    <section className={`accordion-section-default ${backgroundColor}`}>
      <div className="container-xl">
        <div className="row">
          <div className="col-lg-12">
            <h2>
              <RichText field={fields?.heading} className="title" />
            </h2>
            <p>
              <RichText field={fields?.paragraphText} />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            {fields?.items &&
              fields?.items.map((item, index) => (
                <AccordionItem
                  key={item?.fields?.id}
                  title={item?.displayName}
                  text={item?.fields?.paragraphText}
                  isActive={activeIndex === index}
                  index={index}
                  onShow={(index) => setActiveIndex(index)}
                />
              ))}
          </div>
        </div>
        {btnText && (
          <div className="row">
            <div className="col-lg-12">
              <Button text={btnText} />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

Accordion.defaultProps = {
  heading: '',
  paragraphText: '',
  btnText: '',
  btnClass: '',
  cssClass: '',
  backgroundColor: 'light',
  offset: true,
  items: [],
};

Accordion.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  btnText: PropTypes.string,
  btnClass: PropTypes.string,
  cssClass: PropTypes.string,
  backgroundColor: PropTypes.oneOf(['light', 'gray']),
  offset: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

export default Accordion;
