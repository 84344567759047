import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

const AccordionItem = ({ title, text, isActive, index, onShow }) => (
  <div className={`accordion-block ${isActive ? 'active' : ''}`}>
    <button
      className="icon"
      onClick={() => (isActive ? onShow() : onShow(index))}
      aria-expanded={isActive}
    ></button>
    <h3 className="accordion-title" onClick={() => (isActive ? onShow() : onShow(index))}>
      {title}
    </h3>
    <div className="accordion-body">
      <RichText field={text} />
    </div>
  </div>
);

AccordionItem.defaultProps = {
  title: '',
  text: '',
};

AccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default AccordionItem;
